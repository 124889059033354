import type {
    Localization,
} from '@openticket/lib-localization';
import { Log, send } from '@openticket/lib-log';
import { createElement } from '../utils/createElement';
import Style from '../utils/style';
// Import with inline is not accepted by linter
// eslint-disable-next-line import/no-unresolved
import shopWidgetStyle from '../scss/widget.scss?inline';
import { WidgetStatus } from '../widget_types';
import { getTranslationWithFallback } from '../utils/getTranslationWithFallback';
import { StringMessage } from '../logging';

interface WidgetListeners {
    loadComplete: (shopUrl: string, initCallback: (contentWindow: Window) => Promise<void>) => Promise<void>;
    onWidgetOpen: () => void;
    onWidgetClose: () => void;
    onResize: () => void;
}

export class ShopWidgetDom {

    private popup?: HTMLIFrameElement;
    private injectedStyles?: HTMLStyleElement;
    private buttonWrapperButtonSpinner?: HTMLDivElement;
    private buttonWrapperButtonOpenedIcon?: HTMLDivElement;
    private buttonWrapperButtonClosed?: HTMLDivElement;
    private buttonWrapperButtonClosedCount?: HTMLParagraphElement;
    private buttonWrapperButtonClosedEmpty?: {
        img: HTMLImageElement;
        comment: Comment;
    };

    private buttonWrapperButton?: HTMLDivElement;
    private buttonWrapper?: HTMLDivElement;
    private buttonLabel?: HTMLParagraphElement;
    private button?: HTMLDivElement;
    private container?: HTMLDivElement;

    private transitioningTimeout?: number;
    private shakingTimeout?: number;

    private translations?: {
        button_cta_label: string;
        button_cart_label: string;
    };

    private resultListeners?: WidgetListeners;
    private currencyFormatter?: (amountCents: number) => string;
    private debouncing = 0;

    public mount(targetElement: HTMLElement, buttonless: boolean) {
        this.injectedStyles = createElement('style', {}, undefined, shopWidgetStyle);
        targetElement.insertAdjacentElement('beforeend', this.injectedStyles);

        this.popup = createElement('iframe', {
            id: 'ot-shop-widget__popup',
            title: 'shop widget',
            frameBorder: '0',
        }, [ 'ot-shop-widget__popup', 'ot-card' ]);

        this.resultListeners = {
            loadComplete: () => Promise.resolve(),
            onWidgetOpen: () => {},
            onWidgetClose: () => {},
            onResize: () => {},
        };

        if (!buttonless) {
            // Loader/Spinner: not connected
            this.buttonWrapperButtonSpinner = createElement('div', undefined, 'ot-spinner');

            // Close icon (drop down): connected, open (not mobile)
            const svg: SVGSVGElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            svg.setAttribute('fill', 'currentColor');
            svg.setAttribute('viewBox', '0 0 32 32');
            svg.setAttribute('stroke', 'none');
            iconPath.setAttribute(
                'd',
                'M16 18.4277L6.04001 12.2027L4.62668 14.464L15.2933 21.1307H16.7067L27.3733 14.464L25.96 12.2027L16 18.4277Z',
            );
            svg.appendChild(iconPath);
            this.buttonWrapperButtonOpenedIcon = createElement('div', undefined, [ 'oti', 'oti-drop-down', 'ot-shop-widget__button__opened_icon' ], svg);
            this.buttonWrapperButtonOpenedIcon.style.display = 'none';

            // Ticket count: connected, not open, filled cart
            this.buttonWrapperButtonClosedCount = createElement(
                'p',
                undefined,
                [ 'ot-shop-widget__button__closed_count', 'ot-text-intro-strong' ],
                '?',
            );
            this.buttonWrapperButtonClosedCount.style.display = 'none';

            // Logo: connected, not open, empty cart
            this.buttonWrapperButtonClosedEmpty = {
                img: createElement('img', { alt: 'Logo' }, [ 'button__logo' ]),
                comment: document.createComment('logo unavailable'),
            };
            this.buttonWrapperButtonClosed = createElement(
                'div',
                undefined,
                [ 'ot-shop-widget__button__empty' ],
                this.buttonWrapperButtonClosedCount,
                this.buttonWrapperButtonClosedEmpty.comment,
            );
            this.buttonWrapperButtonClosed.style.display = 'none';

            this.buttonWrapperButton = createElement(
                'div',
                {
                    ontouchstart: null,
                },
                [ 'ot-card', 'button', 'ot-shop-widget__button__button' ],
                this.buttonWrapperButtonSpinner,
                this.buttonWrapperButtonOpenedIcon,
                this.buttonWrapperButtonClosed,
            );

            this.buttonWrapper = createElement('div', {
                ariaLabel: 'Open shop widget popup',
                // ariaControls: 'ot-shop-widget__popup', // Property does not exist, moved it to a setAttribute call below
                role: 'button',
                tabIndex: 0,
            }, [ 'ot-shop-widget__button__wrapper', 'button-wrapper' ], this.buttonWrapperButton);
            this.buttonWrapper.setAttribute('aria-controls', 'ot-shop-widget__popup');

            this.buttonLabel = createElement(
                'p',
                undefined,
                [ 'ot-shop-widget__button__label', 'label', 'ot-text-small-strong' ],
                '',
            );
            this.buttonLabel.style.display = 'none';

            this.button = createElement(
                'div',
                undefined,
                [ 'ot-shop-widget__button', 'position' ],
                this.buttonLabel,
                this.buttonWrapper,
            );

            const toggleClick = () => {
                if (this.button) {
                    if (this.button.classList.contains('opened')) {
                        this.closeWidget();
                    } else {
                        this.openWidget();
                    }
                }
            };
            this.buttonWrapper.addEventListener('click', toggleClick);

            // Add Enter + Space toggle on button for a11y support
            this.buttonWrapper.addEventListener('keydown', (e: KeyboardEvent) => {
                const validKeys = [ ' ', 'Enter' ];
                if (validKeys.includes(e.key)) {
                    toggleClick();
                }
            });

            this.container = createElement(
                'div',
                undefined,
                [ 'ot-shop-container', 'ot-shop-widget' ],
                this.popup,
                this.button,
            );
        } else {
            this.container = createElement(
                'div',
                undefined,
                [ 'ot-shop-container', 'ot-shop-widget' ],
                this.popup,
            );
        }

        targetElement.insertAdjacentElement('beforeend', this.container);

        // iframe-resizer
        window.addEventListener('resize', () => {
            if (this.popup) {
                if (this.resultListeners && this.resultListeners.onResize) {
                    this.resultListeners.onResize();
                }
            }
        });
        // Resize the popup initially to the proper size
        this.resize(false);

        this.popup.addEventListener('transitionend', () => this.stopTransitioning());
        this.popup.addEventListener('transitioncancel', () => this.stopTransitioning());

        // Widget click outside close listener
        window.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;

            if (
                (!target || !target.classList || !target.classList.contains('ot-notification'))
                && (this.buttonWrapper && !this.buttonWrapper.contains(target))
                && (this.popup && !this.popup.contains(target))
            ) {
                window.clearTimeout(this.debouncing);
                this.debouncing = window.setTimeout(() => {
                    this.closeWidget();
                }, 50);
            }
        }, {
            // We need this listener to be fired first. This way we can set a debounce mechanism to prevent the click-outside
            // close call to fire just before a host-site button with a toggle call leading to opening and closing in rapid
            // succession
            capture: true,
        });

        this.resultListeners.loadComplete = (
            shopUrl: string,
            initCallback: (contentWindow: Window) => Promise<void>,
        ): Promise<void> => {
            if (!this.popup) {
                send(
                    new StringMessage(
                        'shop.error.shop_widget.dom.iframe.not_found',
                        'Could not find iframe',
                        { shopUrl },
                    ),
                    Log.Debug,
                );

                return Promise.reject();
            }
            this.popup.src = shopUrl;
            if (!this.popup.contentWindow) {
                send(
                    new StringMessage(
                        'shop.error.shop_widget.dom.iframe.inaccessible',
                        'No access to iframe\'s content window',
                        { shopUrl },
                    ),
                    Log.Debug,
                );

                throw new Error('No access to iframe\'s content window');
            }

            let iconUrl!: string;
            if (Style.getAppliedTheme() === 'dark') {
                iconUrl = import.meta.env.VITE_WHITELABEL_ICON_URL
                    || 'https://cdn.openticket.tech/whitelabels/{{whitelabel}}/graphics/icon.svg';
            } else {
                iconUrl = import.meta.env.VITE_WHITELABEL_ICON_DARK_URL
                    || 'https://cdn.openticket.tech/whitelabels/{{whitelabel}}/graphics/icon_dark.svg';
            }
            const { hostname } = new URL(shopUrl);
            const whitelabel = hostname.split('.').splice(-2).join('.');

            if (this.buttonWrapperButtonClosedEmpty) {
                this.buttonWrapperButtonClosedEmpty.img.src = iconUrl.replace('{{whitelabel}}', whitelabel);
            }
            if (
                this.buttonWrapperButtonClosed
                && this.buttonWrapperButtonClosedEmpty
                && this.buttonWrapperButtonClosedEmpty.img
            ) {
                this.buttonWrapperButtonClosed.replaceChild(
                    this.buttonWrapperButtonClosedEmpty.img,
                    this.buttonWrapperButtonClosedEmpty.comment,
                );
            }

            return initCallback(this.popup.contentWindow);
        };

        return this.resultListeners;
    }

    private stopTransitioning() {
        window.clearTimeout(this.transitioningTimeout);
        if (this.popup) {
            this.popup.classList.remove('transitioning');
        }
    }

    private stopShaking() {
        window.clearTimeout(this.shakingTimeout);
        if (this.buttonWrapperButton) {
            this.buttonWrapperButton.classList.remove('animateShake');
        }
    }

    public openWidget() {
        window.clearTimeout(this.debouncing);
        this.stopTransitioning();

        if (this.button) {
            this.button.classList.add('opened');
        }

        if (this.buttonWrapper) {
            this.buttonWrapper.setAttribute('aria-expanded', 'true');
        }
        if (this.popup) {
            this.popup.classList.add('opened');
            this.popup.classList.add('transitioning');
        }

        // Set a fallback here, there is a chance the transition won't start
        // and thus never finish/cancel, which would keep the popup inside the viewport.
        // The timeout is intentionally quite a bit larger than the transition time.
        this.transitioningTimeout = window.setTimeout(() => this.stopTransitioning(), 2000);

        if (this.resultListeners && this.resultListeners.onWidgetOpen) {
            this.resultListeners.onWidgetOpen();
        }
    }

    public closeWidget() {
        window.clearTimeout(this.debouncing);
        this.stopTransitioning();

        if (this.popup) {
            if (this.popup.classList.contains('opened')) {
                // The check for opened is necessary, because otherwise the transitioning class will be
                // added on every click on the host page.
                this.popup.classList.add('transitioning');
            }
            this.popup.classList.remove('opened');
        }

        if (this.buttonWrapper) {
            this.buttonWrapper.setAttribute('aria-expanded', 'true');
        }

        if (this.button) {
            this.button.classList.remove('opened');
        }

        // Set a fallback here, there is a chance the transition won't start
        // and thus never finish/cancel, which would keep the popup inside the viewport.
        // The timeout is intentionally quite a bit larger than the transition time.
        this.transitioningTimeout = window.setTimeout(() => this.stopTransitioning(), 2000);

        if (this.resultListeners && this.resultListeners.onWidgetClose) {
            this.resultListeners.onWidgetClose();
        }
    }

    public shakeButton(): void {
        if (this.buttonWrapperButton) {
            this.buttonWrapperButton.classList.add('animateShake');

            this.buttonWrapperButton.addEventListener('animationend', () => {
                if (this.buttonWrapperButton) {
                    this.buttonWrapperButton.classList.remove('animateShake');
                }
            });

            // Set a fallback here, there is a chance the transition won't start
            // and thus never finish/cancel, which would keep the popup inside the viewport.
            // The timeout is intentionally quite a bit larger than the transition time.
            this.shakingTimeout = window.setTimeout(() => this.stopShaking(), 1000);
        }
    }

    public updateStatus(
        status: typeof WidgetStatus[keyof typeof WidgetStatus],
        numberOfTickets?: number,
        totalPriceCents?: number,
    ): void {
        switch (status) {
            case WidgetStatus.Loading:
                if (this.buttonWrapperButtonSpinner) {
                    this.buttonWrapperButtonSpinner.style.display = 'block';
                }

                if (this.buttonWrapperButtonOpenedIcon) {
                    this.buttonWrapperButtonOpenedIcon.style.display = 'none';
                }

                if (this.buttonWrapperButtonClosed) {
                    this.buttonWrapperButtonClosed.style.display = 'none';
                }
                break;
            case WidgetStatus.Opened:
                if (this.buttonWrapperButtonSpinner) {
                    this.buttonWrapperButtonSpinner.style.display = 'none';
                }

                if (this.buttonWrapperButtonOpenedIcon) {
                    this.buttonWrapperButtonOpenedIcon.style.display = 'block';
                }

                if (this.buttonWrapperButtonClosed) {
                    this.buttonWrapperButtonClosed.style.display = 'none';
                }
                break;
            case WidgetStatus.Closed:
            default:
                if (this.buttonWrapperButtonSpinner) {
                    this.buttonWrapperButtonSpinner.style.display = 'none';
                }

                if (this.buttonWrapperButtonOpenedIcon) {
                    this.buttonWrapperButtonOpenedIcon.style.display = 'none';
                }

                if (this.buttonWrapperButtonClosed) {
                    this.buttonWrapperButtonClosed.style.display = 'block';
                }

                if (numberOfTickets && numberOfTickets > 0) {
                    if (this.buttonWrapperButtonClosedEmpty) {
                        this.buttonWrapperButtonClosedEmpty.img.style.display = 'none';
                    }

                    if (this.buttonWrapperButtonClosedCount) {
                        this.buttonWrapperButtonClosedCount.style.display = 'block';
                        this.buttonWrapperButtonClosedCount.innerText = numberOfTickets?.toString(10) || '0';
                    }

                    if (this.buttonLabel) {
                        this.buttonLabel.setAttribute('data-label-price-cents', totalPriceCents?.toString(10) || '0');
                    }
                } else {
                    if (this.buttonWrapperButtonClosedEmpty) {
                        this.buttonWrapperButtonClosedEmpty.img.style.display = 'block';
                    }
                    if (this.buttonWrapperButtonClosedCount) {
                        this.buttonWrapperButtonClosedCount.style.display = 'none';
                    }
                    if (this.buttonLabel) {
                        this.buttonLabel.setAttribute('data-label-price-cents', '');
                    }
                }
                break;
        }
        this.updateButtonLabel();
    }

    public resize(shouldShowFullscreen: boolean) {
        if (this.popup) {
            const wantedPopupMaxWidth = shouldShowFullscreen ? 'calc(100% - 36px)' : '450px';
            const wantedPopupHeight = window.innerWidth < 625 || shouldShowFullscreen ? '100%' : `${window.innerHeight}px`;

            this.popup.style.height = wantedPopupHeight;
            this.popup.style.maxWidth = wantedPopupMaxWidth;
        }
    }

    public async updateLocale(localization: Localization, locale: string, currency: string) {
        this.currencyFormatter = (amountCents) => localization.formatters.currency(amountCents, currency);

        if (
            locale !== localization.locale.locale
            && localization.isAvailableLocale(locale)
            && localization.isValidLocale(locale)
        ) {
            await localization.setLocale(locale);
        }

        this.translations = {
            button_cta_label: getTranslationWithFallback('widget.button_cta_label', localization, locale),
            button_cart_label: getTranslationWithFallback('widget.button_cart_label', localization, locale),
        };

        this.updateButtonLabel();
    }

    private updateButtonLabel() {
        if (this.buttonLabel && this.translations) {
            this.buttonLabel.style.display = 'block';

            const price = this.buttonLabel.getAttribute('data-label-price-cents');
            if (price === null || price === '' || Number.isNaN(price) || !this.currencyFormatter) {
                this.buttonLabel.innerText = this.translations.button_cta_label;
            } else {
                this.buttonLabel.innerText = this.translations.button_cart_label.replace('{total}', this.currencyFormatter(Number.parseInt(price, 10)));
            }
        }
    }

}
