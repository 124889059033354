import type { Loggable } from '@openticket/lib-log';

export class StringMessage implements Loggable {

    message: string;
    slug: string;
    data?: { [key: string]: string };

    constructor(slug: string, message: string, data?: { [key: string]: unknown }) {
        this.slug = slug;
        this.message = message;

        if (data) {
            try {
                Object.entries(data).forEach(([ key, value ]) => {
                    this.addData(key, value);
                });
            } catch (_) {
                // Explicitly ignore the error
            }
        }
    }

    private addData(key: string, value: unknown): void {
        if (!this.data) {
            this.data = {};
        }

        if (typeof value === 'object') {
            try {
                // Save Dates as ISO strings
                if (value && 'toISOString' in value && typeof value.toISOString === 'function') {
                    const ISOString: unknown = value.toISOString();
                    if (typeof ISOString === 'string') {
                        this.data[key] = ISOString;
                    }
                    return;
                }

                // Try to stringify the object
                this.data[key] = JSON.stringify(value);
            } catch (_) {
                this.data[key] = 'Object that could not serialize';
            }
        } else if (typeof value === 'string') {
            this.data[key] = value;
        } else if (typeof value === 'number' || typeof value === 'bigint'
            || typeof value === 'boolean' || typeof value === 'symbol') {
            this.data[key] = value.toString();
        } else if (typeof value === 'undefined') {
            this.data[key] = 'undefined';
        }
        // Functions are ignored
    }

    getLogContext(): { [key: string]: string } {
        return this.data || {};
    }

}
