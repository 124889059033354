import { OtError } from '@openticket/lib-log';

export interface ShopWidgetErrorData {
    message: string;
    slug: string;
    shop_id: string;
}

export class ShopWidgetError extends OtError {

    constructor(data: ShopWidgetErrorData) {
        super('ShopWidgetError', data.slug, data.message);

        this.captureStackTrace(ShopWidgetError);
        this.additionalData(data);
    }

    private additionalData(data: ShopWidgetErrorData): void {
        this.addContext('shop_id', data.shop_id);
    }

}
