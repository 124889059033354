import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import { Log, register, send } from '@openticket/lib-log';
import { appName, appVersion } from '@openticket/lib-release-meta';
import { ShopWidget } from './components/ShopWidget';
import { ShopWidgetWidgetError } from './utils/errors/ShopWidgetWidgetError';

declare global {
    interface OpenTicketWindow {
        OtShopWidget?: ShopWidget;
    }

    interface Window {
        OpenTicket: OpenTicketWindow;
        OtShopSettings: CustomShopSettingsClient
    }
}

const shopWidget = new ShopWidget();

Object.defineProperty(window, 'OtShopWidget', {
    get: () => shopWidget,
    set: () => {
        console.warn('Re-setting [OtShopWidget] is not allowed, ignoring.');

        send(
            new ShopWidgetWidgetError({
                slug: 'shop.error.shop_widget.ot_shop_widget.resetting_not_allowed',
                message: 'Re-setting [OtShopWidget] is not allowed, ignoring.',
            }),
            Log.Warn,
        );
    },
});

Object.assign(window, { OpenTicket: window.OpenTicket || {} });

Object.defineProperty(window.OpenTicket, 'ShopWidget', {
    get: () => shopWidget,
    set: () => {
        console.warn('Re-setting [OtShopWidget] is not allowed, ignoring.');

        send(
            new ShopWidgetWidgetError({
                slug: 'shop.error.shop_widget.shop_widget.resetting_not_allowed',
                message: 'Re-setting [ShopWidget] is not allowed, ignoring.',
            }),
            Log.Warn,
        );
    },
});

const shopSettings = new CustomShopSettingsClient();

Object.defineProperty(window, 'OtShopSettings', {
    get: () => shopSettings,
    set: () => {
        console.warn('Re-setting [OtShopSettings] is not allowed, ignoring.');

        send(
            new ShopWidgetWidgetError({
                slug: 'shop.error.shop_widget.ot_shop_settings.resetting_not_allowed',
                message: 'Re-setting [OtShopSettings] is not allowed, ignoring.',
            }),
            Log.Warn,
        );
    },
});

const log = register({
    appName,
    appVersion,
    url: import.meta.env.VITE_LOGGER_URL || 'https://notify.openticket.tech/',
});

Object.defineProperty(window, '$log', {
    get: () => log,
});

export {
    ShopWidget,
};
