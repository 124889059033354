import { OtError } from '@openticket/lib-log';

export interface ShopWidgetWidgetErrorData {
    message: string;
    slug: string;
}

export class ShopWidgetWidgetError extends OtError {

    constructor(data: ShopWidgetWidgetErrorData) {
        super('ShopWidgetWidgetError', data.slug, data.message);

        this.captureStackTrace(ShopWidgetWidgetError);
    }

}
